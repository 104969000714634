import * as React from "react"
import Image, { type ImageProps } from "next/image"
import Link from "next/link"
import { twMerge } from "tailwind-merge"

interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  children?: React.ReactNode
  href?: string
  leftIcon?: React.ReactNode
  rightImageProps?: ImageProps
  size?: "small" | "medium" | "large"
  variant?: "basic" | "primary" | "secondary"
  isLoading?: boolean
  loadingVariant?: "spin" | "spin-text"
  spinClass?: string
}

const Button = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      href,
      leftIcon,
      rightImageProps,
      size = "medium",
      type = "button",
      variant = "primary",
      isLoading = false,
      loadingVariant = "spin",
      spinClass = "border-white",
      ...otherProps
    }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement | null>
  ) => {
    const rightImage = React.useMemo(
      () =>
        rightImageProps ? (
          <Image
            {...rightImageProps}
            alt={rightImageProps.alt || "Image"}
            className={twMerge("mr-[0.75em]", rightImageProps?.className)}
          />
        ) : null,
      [rightImageProps]
    )

    const classes = React.useMemo(
      () =>
        twMerge(
          "flex items-center justify-center whitespace-nowrap cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed",
          !href ? "select-none border-0" : "",
          variant === "primary" ? "rounded-full border-0 bg-[#693AC7] text-white" : "",
          variant === "secondary" ? "rounded-full border border-[#693AC7] text-[#693AC7]" : "",
          !href && !disabled ? "hover:bg-color-edecfc" : "",
          variant === "primary" && !disabled ? "hover:bg-[#5e2bc4] focus:bg-[#5e2bc4]" : "",
          variant === "secondary" && !disabled
            ? "hover:border-[#5e2bc4] hover:bg-color-edecfc focus:border-[#5e2bc4] focus:bg-color-edecfc"
            : "",
          ["primary", "secondary"].includes(variant) &&
            !className?.includes("huudle-typography-") &&
            size === "small"
            ? "huudle-typography-12-18-medium"
            : "",
          ["primary", "secondary"].includes(variant) &&
            !className?.includes("huudle-typography-") &&
            size !== "small"
            ? "huudle-typography-14-30-bold"
            : "",
          ["primary", "secondary"].includes(variant) && !leftIcon && size === "small"
            ? "px-2.5 py-1"
            : "",
          ["primary", "secondary"].includes(variant) && !leftIcon && size === "medium"
            ? "p-2.5"
            : "",
          ["primary", "secondary"].includes(variant) && !leftIcon && size === "large" ? "p-3" : "",
          ["primary", "secondary"].includes(variant) && leftIcon && size === "small"
            ? "py-1.5 pl-2.5 pr-3.5"
            : "",
          ["primary", "secondary"].includes(variant) && leftIcon && size === "medium"
            ? "py-3 pl-4 pr-5"
            : "",
          ["primary", "secondary"].includes(variant) && leftIcon && size === "large"
            ? "py-4 pl-5 pr-6"
            : "",
          className
        ),
      [className, disabled, href, leftIcon, size, variant]
    )

    return href ? (
      <Link href={href} className={classes}>
        {leftIcon ?? leftIcon}
        {children}
        {rightImage}
      </Link>
    ) : (
      <button {...otherProps} ref={ref} className={classes} type={type} disabled={disabled}>
        {isLoading ? (
          loadingVariant === "spin-text" ? (
            <div className="flex items-center">
              <div
                className={twMerge(
                  "mr-2 h-5 w-5 animate-spin rounded-full border-b-2 border-white",
                  spinClass
                )}
              />
              <span>{children}</span>
            </div>
          ) : (
            <div
              className={twMerge(
                "h-5 w-5 animate-spin rounded-full border-b-2 border-white",
                spinClass
              )}
            />
          )
        ) : (
          <>
            {leftIcon ?? leftIcon}
            {children}
            {rightImage}
          </>
        )}
      </button>
    )
  }
)

export default Button
