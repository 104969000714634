import * as React from "react"
import Button from "@components/Button"

const NotFound404Page = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <h1 className="huudle-typography-20-24-medium mb-5 text-center text-color-655dc6">
        {"Page not found"}
      </h1>
      <p className="mb-5">{"Oops, something went wrong :("}</p>
      <Button href="/">{"Go to homepage"}</Button>
    </div>
  )
}

export default NotFound404Page
